'use client'
import {useEffect} from "react";
// import { init } from "@socialgouv/matomo-next";
import posthog from 'posthog-js'
import mixpanel from 'mixpanel-browser';

const GoogleAnalytics = ({ga_id}: { ga_id: string }) => {
    useEffect(() => {
        posthog.init('phc_AhgCHXUgljhXj0zO3sdtJkzFwmskxDWUnKCR6HpodpS', {
            // api_host: 'https://eu.posthog.com'
            api_host: 'https://thebullish.trade/ingest',
            ui_host: 'https://eu.posthog.com'
        })
        mixpanel.init("e188838c6ec82c6ded7028eec96e9bf0", {
            debug: false,
            // track_pageview: true,
            persistence: 'localStorage',
            api_host: 'https://mixpanel-tracking-proxy-ukknkwpp3a-uc.a.run.app',
        });

        // init({
        //     url: 'https://matomo-production-0045.up.railway.app/',
        //     siteId: '1'
        // });
    }, []);
    return (
        <>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${ga_id}`}
            ></script>
            <script
                id="google-analytics"
                dangerouslySetInnerHTML={{
                    __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${ga_id}');
        `,
                }}
            ></script>


            <script id={"pixel"} dangerouslySetInnerHTML={{
                __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '193897803764972');
fbq('track', 'PageView');`
            }}>

            </script>

            <noscript><img height="1" width="1"
                           style={{display: "none"}}
                           src={`https://www.facebook.com/tr?id=193897803764972&ev=PageView&noscript=1`}
            /></noscript>

            {/*<Script id={"online-chat"} dangerouslySetInnerHTML={{__html: `window.$crisp=[];window.CRISP_WEBSITE_ID="bb7d86b0-49b1-45fb-a914-71bb276ea292";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}}></Script>*/}

        </>
    );
}
export default GoogleAnalytics;