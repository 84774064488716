import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import GoogleAnalytics from "~/components/google-analytics/google-analytics";
import type {LinksFunction, MetaFunction} from "@remix-run/node";
import stylesheet from "~/tailwind.css?url";
import {CustomThemeProvider} from "~/components/theme-provider/theme-provider";


export const links: LinksFunction = () => [
    // ...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
    { rel: "stylesheet", href: stylesheet },
    {rel: "apple-touch-icon", sizes: '180x180', href: "/apple-touch-icon.png"},
    {rel: "icon", type: "image/png", sizes: '32x32', href: "/favicon-32x32.png"},
    {rel: "icon", type: "image/png",sizes: '16x16',  href: "/favicon-16x16.png"},
    {rel: "manifest", href: "/site.webmanifest"},
];

export const meta: MetaFunction = () => {
    return [
        {title: "The Bullish Trade"},
        {
            property: "og:title",
            content: "The Bullish Trade",
        },
        {
            property: "twitter:title",
            content: "The Bullish Trade",
        },
        {
            name: "description",
            content: "Options trader's secret toolkit",
        },
        {
            property: "og:type",
            content: "website",
        },
        {
            property: "og:url",
            content: "https://thebullish.trade",
        },
        {
            property: "og:description",
            content: "Options trader's secret toolkit",
        },
        {
            property: "twitter:description",
            content: "Options trader's secret toolkit",
        },
        {
            property: "og:image",
            content: "https://thebullish.trade/graphics/1024.jpg",
        },
        {
            property: "twitter:image",
            content: "https://thebullish.trade/graphics/1024.jpg",
        },
        {
            property: "twitter:card",
            content: "summary_large_image",
        },
        {
            property: "twitter:url",
            content: "https://thebullish.trade",
        },
    ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <GoogleAnalytics ga_id={"G-N3C10VN9DD"}/>

        <CustomThemeProvider>
        {children}
        </CustomThemeProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
    console.log(error);
  return <div>Something went wrong (#581) <div>{error?.toString()}</div></div>;
};

export default function App() {
  return <Outlet />;
}